import { type EnrichedSchema, type WorkflowStep, getContentType } from '@newdaycards/spec-tools';
import { EnumValues } from 'components/reference/param-types/param-enum';
import { renderDescription } from 'components/reference/utils';
import { Heading } from 'components/typography/heading';
import { Text } from 'components/typography/text';
// biome-ignore lint/style/useImportType: <explanation>
import { OpenAPIV3 } from 'openapi-types';
import { type MouseEvent, useMemo } from 'react';
import { MdContentCopy, MdOutlineOpenInNew } from 'react-icons/md';
import { sendAnalytics } from 'utils/analytics/google';
import { cn } from 'utils/cn';

interface EnrichedSchemaObjectProperties {
  [name: string]: EnrichedSchema;
}

const EndpointSchema = ({
  schema,
}: {
  schema: EnrichedSchemaObjectProperties;
}) => (
  <div data-testid="step-endpoint-schema" className="flex flex-col gap-4 pt-4">
    {schema &&
      Object.entries(schema).map(([name, values]) => (
        <div key={name}>
          <div className="flex items-center gap-2">
            <Text className="font-mono text-sm font-bold">{name}</Text>
            <span>•</span>
            <div className="text-sm text-blue-700">{`${values.type} ${values.enum ? 'enum' : ''}`}</div>
          </div>
          {values.description && <Text className="my-4 text-sm text-neutral-600">{values.description}</Text>}
          <div className="mt-2 overflow-hidden rounded-md border border-neutral-200">
            <div className="gap-2 bg-neutral-200 p-2">
              <Text className="font-mono text-sm font-medium">Enum values</Text>
            </div>
            {values.enum && values['x-enumNames'] && (
              <EnumValues name={name} enumValues={values.enum} enumLabels={values['x-enumNames']} />
            )}
          </div>
        </div>
      ))}
  </div>
);

export type StepCardProps = {
  step: WorkflowStep;
  stepIndex: number;
  active?: boolean;
  onClick: (stepIndex: number) => void;
};

const StepCard = ({ step, stepIndex, active, onClick }: StepCardProps) => {
  const selectedProperties = useMemo(() => {
    if (step?.outputs?.content === undefined) return {};
    const contentType = getContentType(step.outputs.content);
    const schema = step.outputs.content?.[contentType]?.schema as OpenAPIV3.BaseSchemaObject;
    const { enumFilter } = step;
    const selectedProperties: EnrichedSchemaObjectProperties =
      Object.entries((schema?.properties || {}) as EnrichedSchemaObjectProperties).reduce(
        (properties, [propertyName, property]) => {
          if (propertyName !== 'state') return properties;
          return {
            ...properties,
            [propertyName]: {
              ...property,
              enum:
                enumFilter && property.enum
                  ? property.enum.filter((value) => enumFilter.includes(value))
                  : property.enum,
            },
          };
        },
        {},
      ) || {};
    return selectedProperties;
  }, [step]);

  const handleLinkClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    sendAnalytics('workflowStepLinkClicked', { stepId: step.stepId });
  };

  const handleCopyClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (step.operationPath) {
      navigator.clipboard.writeText(step.operationPath);
    }
  };

  return (
    <div
      data-testid="step-card"
      className={cn(
        'relative rounded-lg border bg-white p-6 transition-all duration-200',
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-purple-400',
        'after:absolute after:left-1/2 after:-bottom-8 after:h-8 after:w-0.5 after:transform after:-translate-x-1/2',
        'after:border-l-2 after:border-dashed after:border-neutral-300 after:last:border-0',
        'dark:text-[#E7E7E7] dark:bg-dark-800',
        active
          ? 'border-purple-400 shadow-lg'
          : 'border-neutral-200 dark:border-neutral-800 cursor-pointer hover:bg-neutral-200 dark:hover:bg-dark-850 ',
      )}
      role="button"
      tabIndex={0}
      onClick={() => onClick(stepIndex)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClick(stepIndex);
        }
      }}
    >
      <div className="flex flex-col gap-2">
        <div className="flex justify-between w-full items-center">
          <div className="flex items-center gap-3">
            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white border border-purple-400 dark:bg-purple-700 dark:border-purple-500">
              <span className="flex h-7 w-7 items-center justify-center rounded-full bg-purple-100 border border-purple-200 dark:bg-purple-700 dark:border-purple-500 font-semibold text-purple-600 dark:text-purple-300">
                {typeof stepIndex !== 'undefined' ? stepIndex + 1 : '?'}
              </span>
            </div>
            <Heading as="h3" className="text-lg font-semibold">
              {step.stepId}
            </Heading>
          </div>
        </div>
        {step.description && (
          <Text className="text-sm text-neutral-600 dark:text-dark-200">{renderDescription(step.description)}</Text>
        )}
      </div>

      {active && (
        <div className="mt-4">
          {step.operationType && step.operationPath && (
            <>
              <div className="mb-2 rounded-md border border-neutral-200 dark:border-dark-600 bg-white p-3 dark:bg-dark-800">
                <div className="flex items-center gap-2">
                  <span className="flex-shrink-0 rounded bg-green-600 px-2 py-1 text-xs font-medium uppercase">
                    {step.operationType}
                  </span>
                  <div className="flex-1 overflow-x-auto scrollbar-thin scrollbar-thumb-neutral-200 scrollbar-track-transparent">
                    <span className="whitespace-nowrap font-mono text-sm">{step.operationPath}</span>
                  </div>
                  <button
                    type="button"
                    onClick={handleCopyClick}
                    className="flex-shrink-0 text-neutral-500 hover:text-neutral-700"
                    aria-label="Copy API path"
                  >
                    <MdContentCopy size={20} />
                  </button>
                </div>
              </div>

              {step.operationLink && (
                <a
                  href={step.operationLink}
                  onClick={handleLinkClick}
                  className="inline-flex items-center gap-1 text-sm text-purple-600 dark:text-purple-300 hover:text-purple-700 font-bold"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See API reference
                  <MdOutlineOpenInNew size={16} />
                </a>
              )}
            </>
          )}
          {step.contentLink && (
            <a
              href={step.contentLink}
              onClick={handleLinkClick}
              className="inline-flex items-center gap-1 text-sm text-purple-600 dark:text-purple-300 hover:text-purple-700 font-bold"
              target="_blank"
              rel="noopener noreferrer"
            >
              See content
              <MdOutlineOpenInNew size={16} />
            </a>
          )}
          {Object.keys(selectedProperties).length >= 1 && <EndpointSchema schema={selectedProperties} />}
        </div>
      )}
    </div>
  );
};

export default StepCard;
