import { MobileNavigation } from 'components/navigation/mobile-navigation';
import { Navigation } from 'components/navigation/navigation';
import type { NavigationNodesMap } from 'components/navigation/navigation-utils';
import { RightNavigation } from 'components/navigation/right-navigation';
import type { ReactNode } from 'react';
import { cn } from 'utils/cn';
import { RootContext } from '../providers/root-provider';
import { Footer } from './footer';
import { Header } from './header';

export function isOverviewOrReferencePage(path: string) {
  const patterns = [
    /^\/domains\/[^\/]+\/[^\/]+\/?$/,
    /^\/domains\/[^\/]+\/[^\/]+\/reference\/?$/,
    /^\/api-products\/[^\/]+\/[^\/]+\/?$/,
    /^\/api-products\/[^\/]+\/[^\/]+\/reference\/?$/,
    /^\/applications\/.+/,
  ];
  return patterns.some((pattern) => pattern.test(path));
}

export type RootLayoutProps = {
  location: Location;
  children?: ReactNode | undefined;
  isLargeContent?: boolean;
  isGreyLayout?: boolean;
  hasRightNav?: boolean;
  navItems: NavigationNodesMap;
  isUserContent?: boolean;
  commitDate?: number;
  isReference?: boolean;
};

export const RootLayout = ({
  hasRightNav,
  location,
  children,
  isLargeContent,
  isGreyLayout,
  navItems,
  isUserContent = false,
}: RootLayoutProps) => {
  const whiteLayout = (
    <div className="flex min-h-screen flex-col" data-testid="page-layout">
      <Header />
      <div className="flex w-full flex-auto">
        <div className="hidden border-r border-neutral-300 bg-white md:block md:w-60 md:min-w-60 lg:w-72 lg:min-w-72 dark:border-dark-500 dark:bg-dark-900">
          <Navigation navItems={navItems} location={location} />
        </div>
        <div className="flex w-full min-w-0 flex-col bg-white dark:bg-dark-900">
          <MobileNavigation location={location} items={navItems} />
          <div className="flex flex-row">
            <main
              className={cn(
                'mx-auto w-full px-4 lg:px-6 mb-[112px]',
                isLargeContent ? 'xl:max-w-[71vw] 2xl:max-w-6xl' : 'max-w-[47em]',
                isUserContent && 'lg:pb-6 xl:pb-12',
              )}
            >
              {children}
            </main>
            {hasRightNav && (
              <div className="no-scrollbar sticky top-0 mr-4 hidden h-screen w-72 overflow-y-auto pb-20 lg:pt-12 xl:block xl:pt-24">
                <RightNavigation location={location} />
              </div>
            )}
          </div>
        </div>
      </div>
      {!isUserContent && <Footer />}
    </div>
  );

  const greyLayout = (
    <div className="flex min-h-screen flex-col" data-testid="page-layout">
      <Header />
      <div className="flex w-full flex-auto bg-[#F6F6F6] dark:bg-dark-900">
        <div className="hidden border-r border-black border-opacity-10 bg-white md:block md:w-[248px] md:min-w-[248px] xl:w-[285px] xl:min-w-[285px] dark:border-dark-500 dark:bg-dark-900">
          <Navigation navItems={navItems} location={location} />
        </div>
        <div className="flex w-full min-w-0 flex-col  ">
          <MobileNavigation location={location} items={navItems} />
          <main className={cn('w-full 3xl:w-[calc(1163px+144px)] py-[42px] px-6 2xl:px-[72px]')}>{children}</main>
        </div>
      </div>
      {!isUserContent && <Footer />}
    </div>
  );

  return <RootContext>{isGreyLayout ? greyLayout : whiteLayout}</RootContext>;
};
