import { NewDayLogo } from 'assets';
import { Link } from 'components/ui/link';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { cn } from 'utils/cn';

export enum FooterLinkType {
  Internal = 0,
  External = 1,
}

export type FooterLink = {
  label: string;
  href: string;
  type: FooterLinkType;
};

export const Footer = () => {
  const { formatMessage } = useIntl();

  const footerLinks: FooterLink[] = [
    {
      href: '/cookie-policy/',
      label: formatMessage({ id: 'pages.cookiePolicy.title' }),
      type: FooterLinkType.Internal,
    },
    {
      href: '/privacy-policy/',
      label: formatMessage({ id: 'pages.privacyPolicy.title' }),
      type: FooterLinkType.Internal,
    },
    {
      href: '/terms-of-use/',
      label: formatMessage({ id: 'pages.termsOfUse.title' }),
      type: FooterLinkType.Internal,
    },
    {
      href: '/support/',
      label: formatMessage({ id: 'pages.support.title' }),
      type: FooterLinkType.Internal,
    },
  ];
  const footerCopyright = formatMessage({ id: 'generic.copyright' });

  const getLink = (footerLinkType: FooterLinkType, label: string, href: string) =>
    footerLinkType === FooterLinkType.Internal ? (
      <Link key={label} href={href} className="text-white hover:text-white dark:text-white dark:hover:text-white">
        {label}
      </Link>
    ) : (
      <Link
        aria-label={`${label}, open in a new tab`}
        key={label}
        href={href}
        className="text-white hover:text-white dark:text-white dark:hover:text-white"
        isExternal
      >
        {label}
        <MdOutlineOpenInNew />
      </Link>
    );

  return (
    <footer
      data-testid="footer"
      role="contentinfo"
      className={cn(
        'flex flex-col justify-between px-5 py-10',
        'lg:flex-row lg:px-20 lg:py-14',
        'bg-dark-900 text-white',
        'dark:bg-dark-900 dark:border-t-[1px] dark:border-dark-700',
      )}
    >
      <div className="flex flex-col items-start gap-4">
        <Link href="/" title="NewDay logo">
          <NewDayLogo data-testid="footerLogo" className="w-32 text-white" />
        </Link>
        <p>{footerCopyright.replace('2022', `${new Date().getFullYear()}`)}</p>
      </div>
      <div className="mb-8 mt-2 flex flex-col items-start gap-1 lg:mb-0 lg:mt-0 lg:flex-row lg:gap-4">
        {footerLinks.map(({ label, href, type }) => getLink(type, label, href))}
      </div>
    </footer>
  );
};
