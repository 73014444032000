module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.13.1_gatsby@4.25.9_@swc+core@1.9.3_@swc+helpers@0.4.37__babel-esl_4hq6pfottp5axxx3lrouui3h7a/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@6.25.0_gatsby-plugin-sharp@4.25.1_gatsby@4.25.9_@swc+core@1.9.3_@swc+hel_ysyfngfbxy7swwxyl4er3texbi/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"disableBgImageOnAlpha":true,"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_gatsby@4.25.9_@swc+core@1.9.3_@swc+helpers@0.4.37__babel-eslint_sgpkto6zfxyxc5agrp2zdogsiu/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NewDay Platform Portal","short_name":"NewDay DevPortal","description":"Platform Portal for NewDay","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo.svg","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"icon_options":{"purpose":"any"},"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"57a18d49cf7b30c59c7c4ff903fdbfb8"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-mdx@3.20.0_@mdx-js+mdx@1.6.22_@mdx-js+react@1.6.22_react@18.2.0__gatsby@4.25.9__tat3wlhjfneqsfwda226fmoyly/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/actions-runner/_work/NewDay.Docs.DevPortal.Web/NewDay.Docs.DevPortal.Web/node_modules/.pnpm/gatsby-remark-images@6.25.0_gatsby-plugin-sharp@4.25.1_gatsby@4.25.9_@swc+core@1.9.3_@swc+hel_ysyfngfbxy7swwxyl4er3texbi/node_modules/gatsby-remark-images","id":"627dbedd-a1e9-5427-8c5f-396aba1440d7","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/home/runner/actions-runner/_work/NewDay.Docs.DevPortal.Web/NewDay.Docs.DevPortal.Web/node_modules/.pnpm/gatsby-remark-images@6.25.0_gatsby-plugin-sharp@4.25.1_gatsby@4.25.9_@swc+core@1.9.3_@swc+hel_ysyfngfbxy7swwxyl4er3texbi/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":800},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/actions-runner/_work/NewDay.Docs.DevPortal.Web/NewDay.Docs.DevPortal.Web","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@6.25.0_gatsby-plugin-sharp@4.25.1_gatsby@4.25.9_@swc+core@1.9.3_@swc+hel_ysyfngfbxy7swwxyl4er3texbi/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.25.9_@swc+core@1.9.3_@swc+helpers@0.4.37__babel-eslint@10.1.0_eslint@7.32.0__esbuild_chs6qae3fkkhz3ddmmz67udiry/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
